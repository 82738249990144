// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

import { init, configureScope, Integrations } from '@sentry/browser';
import { Dedupe } from '@sentry/integrations';

export default ({ sentry }) => {
    if (!sentry) {
        return;
    }

    const { tags = {}, ...options } = sentry;
    init({
        integrations: [
            new Integrations.Breadcrumbs({ console: false }),
            new Dedupe(),
        ],
        ...options,
    });
    configureScope(scope => {
        scope.setTag('logger', 'javascript');
        for (const tag in tags) {
            scope.setTag(tag, tags[tag]);
        }
    });
};
