// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

import { PopupForm } from './popup-form';
import escapeHtml from './escapeHtml';

export const CustomDescription = {};
CustomDescription.PopupForm = class extends PopupForm {
    _initConfiguration(config) {
        super._initConfiguration(config);
        this._hint = this._getConfigParam('hint', '');
        this._value = this._getConfigParam('value', '');
        this._handler = this._getConfigParam('handler', null);
        this._sendButtonId = this._getConfigParam('sendButtonId', 'btn-send');
        this._cancelButtonId = this._getConfigParam('cancelButtonId', 'btn-cancel');
    }

    render() {
        super.render();

        this.setBoxType('form-box');
        this.setTitle(this.lmsg('popupTitle'));
        this.setTitleType(this._getConfigParam('titleCls', 'pp-edit-description'));

        document.getElementById(this._contentAreaId).innerHTML = (
            `<p>${this._hint}</p>` +
            `<textarea id="${this._id}-description" class="js-auto-resize" rows="4" maxlength="255" cols="80">${escapeHtml(this._value)}</textarea>`
        );

        this.addOkButton();
        this.addCancelButton();
    }

    addOkButton() {
        this.addRightButton(this.lmsg('buttonOk'), this._onOkClick, true, true, { id: this._sendButtonId });
    }

    addCancelButton() {
        this.addRightButton(this.lmsg('buttonCancel'), this._onCancelClick, false, false, { id: this._cancelButtonId });
    }

    _onOkClick() {
        this._handler(document.getElementById(`${this._id}-description`).value);
        this.hide();
    }

    _onCancelClick() {
        this.hide();
    }
};
